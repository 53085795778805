<template>
	<main id="paxExpress" class="pax_global">
		<div class="tabs mb-3 d-lg-none">
			<ul class="nav nav-tabs nav-justified">
				<li class="nav-item"><a class="nav-link" :class="{'active' : step < 3, }" @click="openMenu"><b><font-awesome-icon :icon="['fas', 'home']"/></b><span>{{ $t('pax.accueil') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 3, 'active': step === 3}" @click="goToStep(3)"><b>1</b><span>{{ $t('pax.juments') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 4, 'active': step === 4}" @click="goToStep(4)"><b>2</b><span>{{ $t('pax.etalons') }}</span></a></li>
				<li class="nav-item"><a class="nav-link" :class="{'disabled' : step < 5, 'active': step === 5}" @click="goToStep(5)"><b>3</b><span>{{ $t('pax.comparaison') }}</span></a></li>
			</ul>
		</div>
		
		<div id="paxReverse" class="container-fluid">
			<div class="row justify-content-center">
				<ChoixLangue v-show="step === 1" @langue_choosed="goToCountry"/>
				<ChoixCountry v-show="step === 2" @country_choosed="goToPax"/>
				<template v-if="step > 2">
					<div class="col bstallion" v-show="step === 3 || ((isLg || isXl) && step === 4)">
						<Mares :mares_selected.sync="mares_selected" :step_label.sync="step_label"></Mares>
					</div>
					<div class="col" v-show="step == 4">
						<SelectionStallion ref="stallions" :stallion_selected.sync="stallion_selected" :step_label.sync="step_label"></SelectionStallion>
						<ResultCompatibiliteHReverse v-if="stallion_selected && stallion_selected.horse_id" :stallions="mares_selected" :criteres="criteres" :step_label.sync="step_label" :stallions_comparaison.sync="mares_comparaison" :mare="stallion_selected"></ResultCompatibiliteHReverse>
					</div>
					<div class="col-12" v-if="step === 5">
						<ResultCompatibiliteVReverse :stallions="mares_comparaison" :step.sync="step" :criteres="criteres" :mare="stallion_selected" :step_label.sync="step_label" :mare_graphique.sync="mare_graphique"></ResultCompatibiliteVReverse>
					</div>
					<div class="col-12" v-if="stallion_selected && step === 6">
						<PaxGraphiqueReverse :step.sync="step" :mare="mare_graphique" :stallion="stallion_selected" :step_label.sync="step_label"></PaxGraphiqueReverse>
					</div>
				</template>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
    import Constants from 'Constants'
	import UserMixin from '@/mixins/User.js'
	import Navigation from '@/mixins/Navigation.js'
	import PaxMixin from '@/mixins/Pax.js'
	import Config from '@/mixins/Config.js'
	import Window from '@/mixins/Window.js'
	import _uniqueId from 'lodash/uniqueId'

	export default {
		name: "PaxReverse",
		mixins: [UserMixin, Navigation, PaxMixin, Config, Window],
		data () {
			return {
				mares_selected: [],
				stallion_selected: null,
				criteres: [],
				mare: {},
				force_refresh: false,
				mare_graphique: null,
				step: 1,
				step_label: "",
				mares_comparaison: [],
				steps: {'comparaison_V': 5, 'mares_selected': 4, 'stallions': 3, 'country': 2, 'pax_graphique': 6},
			}
        },
		created() {
            this.init_component()
		},
		methods: {
			async init_component() {
				document.querySelector('html').classList.remove('no_scroll')
				this.mares_selected = []
				this.stallion_selected = null
				this.criteres = []
				// au reload permet de mettre à jour les critères à l'étape pax
				this.force_refresh = _uniqueId()

				const lang = this.getConfig('lang')
				const country = this.getConfig('country')
				if(lang && country) {
					this.step = 3
				}
				else if(lang && !country) {
					this.step = 2
				}
				else {
					this.step = 1
				}
			},

			async goToStep(step_to_go) {
				if(step_to_go < this.step) {
					this.step_label = Object.keys(this.steps).find(key => this.steps[key] === step_to_go)
				}
			},

			goToCountry() {
				this.step = 2
			},

			goToPax() {
				this.step = 3
			},

			goToHome() {
				this.init_component()
			},

			openMenu() {
				this.$store.commit('nav/open')
			},

			async getStallionCritere() {
				if(this.stallion_selected && this.stallion_selected.horse_id) {
					const criteres_horse = await this.getCaracterisationByHorse(this.stallion_selected.horse_id)
					const criteres = await this.getCriteres(this.lang)

					let criteres_horse_info = []
					criteres.forEach(critere => {
						let horse = criteres_horse.find(c => c.caracterisation_criteres == critere.criteres_id)
						criteres_horse_info.push({
							criteres_id: critere.criteres_id,
							criteres_max: critere.criteres_max,
							criteres_min: critere.criteres_min,
							criteres_red: critere.criteres_red,
							criteres_green: critere.criteres_green,
							critereslang_label: critere.criteres_lang[0].critereslang_label,
							slider: horse.caracterisation_valeur/100
						})
					})
					this.criteres = criteres_horse_info
				}
			}
		},
		computed: {
			lang() {
				return this.getConfig('lang') ?? false
			},
			country() {
				return this.getConfig('country') ?? false
			},
		},
		watch: {
			step_label(val) {
				this.step = this.steps[val]
			},
			stallion_selected(val) {
				this.getStallionCritere()
			}
		},
		components: {
			SelectionStallion : () => import('@/components/Pax/SelectionStallion'),
			Mares : () => import('@/components/Pax/Mares'),
			ResultCompatibiliteHReverse : () => import('@/components/Pax/ResultCompatibiliteHReverse'),
			ResultCompatibiliteVReverse : () => import('@/components/Pax/ResultCompatibiliteVReverse'),
			ChoixLangue: () => import('@/components/Pax/ChoixLangue'),
			ChoixCountry: () => import('@/components/Pax/ChoixCountry'),
			PaxGraphiqueReverse : () => import('@/components/Pax/PaxGraphiqueReverse')
		}
	};
</script>
